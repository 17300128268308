// Fader V6.32 SASS
// used for fading in items

.fader {
  // set fader vars
  --vert: var(--padding);
  --hor: var(--gutter);
  --move-duration: 0.5s;
  --opacity-duration: 0.5s;

  will-change: transform, opacity, clip-path;
  transition-delay: 0s; // overwritten in js!
  transition-timing-function: cubic-bezier(0, 0, 0.04, 1) !important;
  transition-property: transform, opacity, clip-path !important;

  .no-js & {
    animation: fader-fader-in var(--move-duration);
    animation-fill-mode: both;
    animation-delay: 0.4s; // by then js has loaded

    @keyframes fader-fader-in {
      0% {
        transform: translateY(calc(var(--vert) * 1));
        opacity: 0;
      }

      100% {
        transform: none;
        opacity: 1;
      }
    }
  }

  .body--firefox & {
    .js & {
      transform: none;

      &.fader--top-start { transform: none; }

      &--clip {
        transform: translateY(calc(var(--vert) * 1));  // this has sub-pixel animation, so it's allowed

        &.fader--top-start {
          transform: translateY(calc(var(--vert) * -1));  // this too
        }
      }

      &--up {
        transform: translateY(100%); // this has sub-pixel animation, so it's allowed

        &.fader--top-start {
          transform: translateY(-100%);  // this too
        }
      }
    }
  }

  .js & {
    transform: translateY(calc(var(--vert) * 1));
    opacity: 0 !important;

    // some basic animation classes, more could be added
    // transforms are pre-fade

    // .fader
    &--top-start { transform: translateY(calc(var(--vert) * -1)); } // before others to not override

    &--shrink { transform: scale(1.1); }
    &--left { transform: translateX(calc(var(--hor) * -1)); }
    &--right { transform: translateX(calc(var(--hor) * 1)); }

    &--bl {
      transform: translate(calc(var(--hor) * -1), var(--vert));

      &.fader--top-start { transform: translate(calc(var(--hor) * -1), calc(var(--vert) * -1)); }
    }

    &--br {
      transform: translate(calc(var(--hor) * 1), var(--vert));

      &.fader--top-start { transform: translate(calc(var(--hor) * 1), calc(var(--vert) * -1)); }
    }

    &--still {
      will-change: opacity;
      transform: initial; // override top for instance
      transition-property: opacity !important;
    }

    &--fullscreen,
    &--no-fade {
      will-change: auto;
      transform: none;
      opacity: 1 !important;
      transition-property: none !important;
    }

    &--clip {
      transform: translateY(calc(var(--vert) * 1));
      opacity: 1 !important;
      clip-path: inset(100% 0 0 0);

      &.fader--top-start { transform: translateY(calc(var(--vert) * -1)); }
    }

    &--up {
      transform: translateY(100%);
      opacity: 1 !important;

      &.fader--top-start { transform: translateY(-100%); }
    }

    &--fast-load {
      --move-duration: 0s;
      --opacity-duration: 0.1s;

      transform: none;
    }

    // &--no-scroll {} // fades a loader even when not above fold
    // &--no-stagger {} // only has effect in script
    // &--below-fold {} // fades even below fold, handy for element with transition

    // set pointer events to none for elements that have a interfering hover
    // change in the hover action
    &.nav__item { pointer-events: none; }

    // set which not yet loaded elements take up space
    // &.fader--loader:not(.fader--loaded) {
    //   // change for every project
    //   // &.image,
    //   // &.image__img,
    //   &.video,
    //   &.video__video {
    //     min-height: 30vw;
    //   }
    // }

    // set a different animation for certain element
    // .fader
    &.video--cover,
    &.cover {
      transform: scale(1.05);
    }

    // do the animation
    // .fader
    &3:not(.fader--loader, .fader--loading),
    &3.fader--loaded {
      transform: none !important;
      opacity: 1 !important;

      // transform, opacity, clip-path
      transition-duration: var(--move-duration), var(--opacity-duration), var(--move-duration) !important;

      &.fader--still { transition-duration: var(--opacity-duration) !important; }
      &.fader--no-fade { transition-duration: 0s !important; }
      &.fader--clip { clip-path: inset(0 0 0 0); }
    }

    // .fader
    @media (max-width: 800px) {
      &--bl {
        transform: translate(0, var(--vert));
        &.fader--top-start { transform: translate(0, calc(var(--vert) * -1)); }
      }

      &--br {
        transform: translate(0, var(--vert));
        &.fader--top-start { transform: translate(0, calc(var(--vert) * -1)); }
      }
    }
  }
}
