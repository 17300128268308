@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;

.main {
  position: relative;
  transition: opacity, transform;
  transition-timing-function: ease, var(--transition-easing-out);
  transition-duration: var(--transition-default);

  .html--loader & {
    transform: translateY(var(--gutter-large));
    opacity: 0;
    transition-timing-function: ease, var(--transition-easing-in);
  }

  .html--loader .body--firefox & {
    transform: none;
  }

  .main__with-bg {
    @include mixins.white-start;

    --bg-color: var(--colors-bg-one);

    display: flex;
    flex-direction: column;
    padding-top: calc(var(--gutter-large) * 2);

    &--home { padding-top: 10vmax; }

    &--img-article,
    &--rentes {
      padding-top: 0;

      > *:first-child { margin-top: calc(var(--overlap) * -1); }
    }

    &--rentes { --overlap: calc(var(--interest-header-height) + 10vmax); }
    &--img-article { --overlap: calc(var(--article-img-height) * 0.5); }

    &--banks {
      margin-top: calc(var(--nav-height) * -1);
      padding-top: calc(var(--nav-height) + var(--gutter-large) * 2);
    }

    &:first-child {
      &::before,
      &::after { content: none; }
    }
  }

  .main__rest {
    --bg-color: var(--colors-bg-one);

    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color);

    &--padding-top { padding-top: calc(10vmax - var(--padding)); }
  }

  @media (max-width: vars.$mq-mobile) {
    .main__with-bg {
      &--rentes > *:first-child {
        margin-top: var(--gutter-medium);
      }
    }
  }
}
